<template>
<div id="scrollcontainer">
<div id="content">
		<h1 id="deepGen">Om JoelGPT</h1>
		<div id="message" class="message smallerText">
			
            <strong>JoelGPT</strong> består dels av den här webbsidan och dels av en maskininlärningsbaserad textgenerator som försöker skapa texter i samma stil som Joel Petterssons texter.
            <h3>Joel Pettersson</h3>
            <strong>Joel Pettersson</strong> (1892 - 1937) var en åländsk konstnär och författare. 
            <br />Observera att ingen av texterna som presenteras här har författats av Joel Pettersson! Petterssons verk har använt som förlagae för textgeneratorn, men själva texterna är helt och hållet datorgenererade.
            
            <h3>Modellerna</h3>
            Textgeneratorn består av en maskininlärningsmodell, JoelGPT, som är baserad på <a href="https://huggingface.co/flax-community/swe-gpt-wiki" target="_blank">swe-gpt-wiki</a>, en svensk textgeneratormodell baserad på <a href="https://huggingface.co/gpt2" target="_blank">GPT-2</a>. JoelGPT har finjusterats (fine-tuning) med Joel Petterssons texter för att generera texter i liknande stil.

            <br />Givet en textsträng (i det här fallet en godtycklig mening) som matas in i modellen, "förutsäger" modellerna sannolikheterna för möjliga nästa ord i sekvensen.
            <br /><br />Modellen tränad med <a href="https://github.com/minimaxir/aitextgen" target="_blank">aitextgen</a>.

            <h3>Textgenerering</h3>


            <h3>Webbsidan</h3>
            Webbsidan presenterar alltså meningar som textgeneratorn producerat. Webbsidan väljer slumpmässigt ett antal meningar (minst två och högst 10) från de genererade texterna och visar dem för användaren. Användaren kan se nya texter genom att trycka på knappen "Fram med sanningen, slyngel!" (en genuin replik från Fältskärns berättelser).
                
            <br /><br />Texterna har genererats på förhand, av vilka ett antal har valts ut för presentation på den här webbisdan. En mindre andel av texterna (ungefär 25%) har i viss mån redigerats manuellt, övriga har lagts till utan manuell redigering. Grammatik (särskilt genuskongruens) och nonsensord har korrigerats minimalt, och flertalet har lämnats okorrigerade i underhållingssyfte. Modellerna är helt och hållet teckenbaserade, och semantik eller sammanhang tas inte i beaktande; textgeneratorn försöker så gott den kan att skapa texter i liknande stil som Topelius, utan någon hänsyn till substans.
            <br /><br />Summa summarum: texterna som genereras är total nonsens :) (eller så får läsaren själv tolka vad de kan tänkas betyda...)
                
            <br /><br />Nya meningar läggs till och redigeras sporadiskt, när andan faller på. För närvarande finns c:a 600 meningar för webbsidan att välja mellan (kring 6 * 10<sup>27</sup> möjliga kombinationer av meningar) att välja mellan).

            <br /><br />Webbsidan är skapat med hjälp av webbramverket <a href="https://vuejs.org/" target="_blank">Vue 2.X</a>.
                    
            <h3>Vad är poängen med detta?</h3>    
            Det kan man fråga sig.
            <br />Ursprungligen var det fråga om ett experiment med textgenerering på svenska: en stor del av litteraturen och material online om språkteknologi kretsar kring engelska och de större språken, och vi ville helt enkelt prova hur väl man kunde tillämpa textgenereringsmetoder som använts för engelska på svenska. 
            <br />
            <h3>Dela texter</h3>    
            Ifall du stöter på särskilt intressanta eller underhållande textpassager som du vill dela med dig av, uppdateras adressfältet automatiskt med ett unikt identifikationsnummer; för att dela just den specifika texten, kopiera hela adressen inklusive identifikationsnumret.

            <h3>Rättigheter</h3>   
            <br />Joel Petterssons självporträtt är taget från <a href="https://upload.wikimedia.org/wikipedia/commons/1/1b/Joel_Petterssons_sj%C3%A4lvportr%C3%A4tt.jpg" target="_blank">Wikimedia Commons</a>, fotograf Fredrik Karlsson.
            <br />Typsnittet i rubriken, <a href="https://www.1001fonts.com/riotsquad-font.html">Riot Squad NF</a>, skapat av av Nick Curtis.
            <br /><br />Ansvarig utgivare för webbsidan och modellerare: Michael Stormbom (michael@stroberock.com) vid Stroberock (<a href="https://www.stroberock.com/" target="_blank">www.stroberock.com</a>).
            <br /><br />Webbsidan © 2022 Stroberock. All rights reserved.
		</div>
		
		<router-link to="/" class="infoquestion">Tillbaka till huvudsidan</router-link>
        <!--<div id="strobelogo"><a href="https://stroberock.com/" target="_blank"><img id="strobeimg" src="/assets/img/strobelogo_bw.png" alt="Skapat av Stroberock"/></a></div>-->
	</div>
</div>
</template>

<script>

export default {
  name: 'About',
  data: () => ({}),
		methods: {
			
		},
	mounted: function() {
        document.getElementById("message").style.opacity = 1;
        document.getElementById("deepGen").style.opacity = 1;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
	created: function() {
       // document.getElementById("message").style.opacity = 1;
        // document.getElementById("deepTopelius").style.opacity = 1;       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
