<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => { return {}}
}
</script>

<style>
@font-face {
  font-family: classy_af;
  src: url("/assets/fonts/riotsquad.regular.otf");
}

html {
	height: 100%;
	padding: 0;
	margin: 0;
}

body {
	padding: 0;
	margin: 0;
	height: 100%;
	background: rgb(255, 247, 239);
	background-image: url("/assets/img/joel_pettersson.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top right;
	background-attachment: fixed;
  font-family: "Garamond", "Times New Roman", Times, serif;
  overflow: hidden;
}


h1 {
	
	width: 100%;
	font-size: 60pt;
	margin-bottom: 4%;
	font-family: classy_af;
}

h2 {
	font-size: 32pt;
	text-align: center;
}

 h3 {
    font-size: 20pt;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  h4 {
    font-size: 16pt;
    padding-bottom: 0;
    margin-bottom: 0;
  }


#app {
  height: 100%;
}

#scrollcontainer {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y:auto; 
  overflow-x:hidden;
  height: 100%;
}

#content {
	width: 55%;
	max-width: 55%;
	min-width: 55%;
	padding: 10pt;
	padding-top: 2%;
	text-align: center;
}


.transitioner {
  opacity: 0;
	transition: opacity 1s;
  
}

.message {
	font-size: 24pt;
	color: #333;
	font-style: normal;
	margin-bottom: 5%;
	text-align: left;
	width: 80%;
	margin-left: 12%;

}

.smallerText {
  font-size: 18pt;
  color: #333;
}

.monospace {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12pt;
}

#generatorButton {
	font-size: 18pt;
	font-style: italic;
  background-color: black;
  border: 2px solid black;
  color: white;
  border-radius: 8px;
  padding: 10pt;
  font-family: 'Times New Roman', Times, serif;
}

#generatorButton:hover {
  background-color: #666;
  border: 2px solid #666;
  color: white;
}

a:link, a:visited {
  color: black;
  font-weight: bold;
}

a:active, a:hover {
  color: black;
}

.infoquestion {
  font-style: italic;
  font-size: 16pt;
} 

#strobelogo {
  position: fixed;
  right: 100px;
  bottom: 10px;
  font-style: italic;
  color: white;
  font-size: 14pt;
  text-align: center;
}

#strobeimg {
  width: 180px;
}

@media only screen and (max-width: 1023px) {
	body {
		padding: 0;
		margin: 0;
		height: 100%;
		background: white;
  }
  
  h1 {
		width: 100%;
		font-size: 32pt;
		margin-bottom: 4%;
		font-family: classy_af;
	}

	h2 {
		font-size: 16pt;
		text-align: center;
	}

  h3 {
		font-size: 16pt;
		text-align: center;
	}

 
  #content {
		width: 95%;
		max-width: 95%;
		min-width: 95%;
		padding: 10pt;
		padding-top: 2%;
		text-align: center;
	}

  #generatorButton {
	font-size: 12pt;
	font-style: italic;
  background-color: black;
  border: 2px solid black;
  color: white;
  padding: 8pt;
  font-family: 'Times New Roman', Times, serif;
  }

  .message {
		font-size: 14pt;
		color: #333;
		font-style: normal;
		margin-bottom: 4%;
		text-align: left;
		width: 80%;
		margin-left: 10%;
	}

  .infoquestion {
    font-style: italic;
    font-size: 12pt;
    color: #333;
  } 

  .smallerText {
  font-size: 12pt;
  color: #333;
}

.monospace {
  font-family: 'Courier New', Courier, monospace;
  font-size: 10pt;
}

  #strobelogo {
  position: relative;
  right: 0;
  left: 0;
  margin: 0%;
  margin-top: 10px;
  padding: 0;
  width: 100%;
  min-width: 100%;
  font-style: italic;
  color: black;
  font-size: 10pt;
  text-align: center;
  }

#strobeimg {
  width: 150px;
  margin: 0;
  padding: 0;
}
}
</style>
