import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.config.productionTip = false

import DeepGen from './components/DeepGen.vue'
import About from './components/About.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', meta: { title: 'JoelGPT' }, component: DeepGen },
  { path: '/Om', component: About, meta: { title: 'Om JoelGPT' }},
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  /* It will change the title when the router is change*/
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

