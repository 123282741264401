<template>
<div id="scrollcontainer">
<div id="content">
		<h1 id="deepGen" class="transitioner">JoelGPT</h1>
		<div id="message" class="message transitioner">
			<h2 id="headline" v-show="displayHeadline.length>0">{{ displayHeadline }}</h2>
			<span v-for="(m, index) in displayMessage" v-bind:key="index"><span v-show="m.breakFor"><br /></span>{{ m.text }}<span v-show="m.breakAfter"><br /></span><span v-show="m.doubleBreakAfter"><br /></span><span v-show="m.spaceAfter">&nbsp;</span></span>
		</div>
		<div>
			<button id="generatorButton" class="transitioner" v-on:click="newMessage(null)">{{ buttonMessage }}</button>
		</div>
		<div style="text-align: center; margin-top: 10px"><router-link to="/Om" id="infolink" class="infoquestion transitioner">Vad är detta?</router-link>
		</div>
	</div>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: 'DeepGeen',
  data: () => ({
			debug: true,
			
			minMessageLength: 5,
			consecutiveSentences: 4,
			messageLength: 15,
			characterLimit: 640,
			headlineCharacterLimit: 45,
			timeout: 750,
			curChars: 0,
			message: '',
			messages: [],
			displayHeadline: "",
			displayMessage: [],
			buttonMessage: 'Säj det.',
			title: 'DeepGen',
			base32chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567",
			base64chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+!",
			file:"/assets/pettersson_generated_sentences_edited.txt",
			currentHash: "",
			hashLength: 2,
		}),
		methods: {
			convertBaseToDecimal: function(baseString) {
				let value = 0;
				
				for (var i = baseString.length-1; i>=0; i--) {
					let j = (baseString.length - 1) -  i;
					console.log(j);

					let inValue = this.base64chars.indexOf(baseString[i]);
					for (let k = 1; k <= j; k++) {
						inValue = inValue * 64;
					}
					console.log(baseString[i] + " (pos "+j+") --> " + inValue + ", ori " + this.base64chars.indexOf(baseString[i]));
					value += parseInt(inValue);
				}
				console.log(baseString + " = " + value);
				return value;
			},
			convertDecimalToBase: function(decimalNumber, pad=0) {
				let baseString = "";
				let numCalc = decimalNumber;
				
				while (numCalc!=0) {
					let quotient  = numCalc / 64.;
					
					let remainder = quotient - Math.floor(quotient);
					
					quotient = Math.floor(quotient);
					remainder = remainder * 64;
					baseString = this.base64chars[remainder] + baseString;
					numCalc = quotient;
				}

				if (baseString.length<pad) {
					let diff = pad-baseString.length;
					for (let i = 0; i < diff; i++) {
						baseString = "A"+baseString;
					}
				}
				return baseString;
			},
			newMessage: function (hash=null) {
				if (this.debug) console.log("hash: "+hash);
				document.getElementById("generatorButton").disabled = true;
				document.getElementById("message").style.opacity = 0;
				document.getElementById("generatorButton").style.opacity = 0;
				document.getElementById("infolink").style.opacity = 0;
				

				let useHash = false;
				if (hash!=null && (hash.length)%this.hashLength==0 && (hash.length)>0) {
					useHash = true;
					if (this.debug) console.log("hash detected, using for production");
				} else {
					if (this.debug) console.log("no valid hash detected, isNull = " + (hash!=null));
					if (this.debug && hash!=null) console.log("mod and length: " + " " + (hash.length%this.hashLength) + " " + hash.length);
				}
				let genHash = "";
				setTimeout(function(scope) {
					let intsAdded = [];
					let breakInserted = false;
					scope.displayMessage = [];
					scope.curChars = 0;
					scope.displayHeadline = "";
					let attempts = 0;
					let randInt = Math.floor(Math.random() * scope.messages.length);


					let indices = [];
					let headline_idx = -1;
					if (useHash) { // parse ahead of time, if anyone fails, go random
						for (let j =0; j < hash.length; j += scope.hashLength) {
								let newInt = scope.convertBaseToDecimal(hash.substring(j,j+scope.hashLength));
								if (newInt < 0 || newInt > scope.messages.length) {
									useHash = false; // if it doesn't parse correctly, don't do anything
									console.log("hash cannot be parsed, value:  " +newInt);
									break;
								}
								if (j>0) indices.push(newInt);
								else headline_idx = newInt;
						}
					} 

					// headline
					if (!useHash) {
						genHash = "AA";
						headline_idx = -1; // this also resets if usehash had been true and was able to parse the title
						while (scope.displayHeadline == 0 && attempts <= 50) {
							while (intsAdded.includes(randInt)) {
								randInt = Math.floor(Math.random() * scope.messages.length);
							}

							if (scope.messages[randInt].length <= scope.headlineCharacterLimit) {
								scope.displayHeadline = scope.messages[randInt].replace("– ",""); //
								genHash = scope.convertDecimalToBase(randInt, scope.hashLength);
								headline_idx = randInt;
								
								break; 
							}
							attempts+=1;
						}
							// TODO generate batches of three consecutive to make it more coherent
							randInt = Math.floor(Math.random() * scope.messages.length);  // just to reset it from the headline
							let curCons = 1;
							console.log(curCons%scope.consecutiveSentences)
							for (let i = 0; i < scope.messageLength; i++) {
								if (curCons%scope.consecutiveSentences == 0 || (randInt+1)>=scope.messages.length ) { // reset - 
									while (intsAdded.includes(randInt)) {
										randInt = Math.floor(Math.random() * scope.messages.length);
									}
									curCons = 1;
								} else {
									randInt = randInt +1;
									curCons++; 
								}
								intsAdded.push(randInt);
								indices.push(randInt);
							}
						
					} else {
						if (scope.messages[headline_idx].length <= scope.headlineCharacterLimit) {
							scope.displayHeadline = scope.messages[headline_idx].replace("– ",""); // 
						}
					}


					// messages
					randInt = Math.floor(Math.random() * scope.messages.length); 
					let truCounter = 0;
					if (scope.debug) { console.log("selected indices: "); console.log(indices); }
					for (let i = 0; i < indices.length; i++) {
					
						
						randInt = indices[i]; // pregenerated, either using the hashes or randomly

						let setBreakFor = false;
						let setBreakAfter = false;
						let setSpaceAfter = true;
						let setDoubleBreakAfter = false;

						if ((scope.curChars + scope.messages[randInt].length) >= scope.characterLimit) { // if adding the new row would exceed the character limit
							if (truCounter >= scope.minMessageLength) { // to ensure at least some messages
								if (scope.debug) console.log("Exceeding and breaking");
								scope.displayMessage[truCounter-1].breakAfter = false; // nothing new has been added yet, i is the most recent addition
								scope.displayMessage[truCounter-1].doubleBreakAfter = false;
								scope.displayMessage[truCounter-1].spaceAfter = false;
								if (scope.debug) console.log("prev: " + scope.displayMessage[truCounter-1].text + " : " + scope.displayMessage[truCounter-1].breakAfter + " " + scope.displayMessage[truCounter-1].spaceAfter);
								break;
							}
						}

						if (scope.messages[randInt].indexOf('–') == 0|| scope.messages[randInt].indexOf('—') == 0) {
							if (i > 0 && scope.displayMessage[i-1].breakAfter == false) setBreakFor = true; // don't do double breaks
							if (i < scope.messageLength-1) setBreakAfter = true; // don't add a break on the last

							setSpaceAfter = false;

						}
						
						scope.curChars += scope.messages[randInt].length;

						if (scope.curChars >= scope.characterLimit/2 && breakInserted == false) {
							setSpaceAfter = false;
							setBreakAfter = true;
							setDoubleBreakAfter = true;
							breakInserted = true;
						}

						if (i == scope.messageLength-1) {
							setSpaceAfter = false;
							setBreakAfter = false;
							setDoubleBreakAfter = false;
						}

						if (scope.debug) console.log(setBreakFor + "/"+setBreakAfter+"/"+setSpaceAfter + ":" + (scope.messages[randInt].indexOf('–') == 0) + " for " + scope.messages[randInt]);

						scope.displayMessage.push({text: scope.messages[randInt], breakFor: setBreakFor, breakAfter: setBreakAfter, doubleBreakAfter: setDoubleBreakAfter, spaceAfter: setSpaceAfter});

						intsAdded.push(randInt); // we only add this for the messages, it is okay if the headline contanins a repeat.
						truCounter+=1;

						if (!useHash) {
							genHash = genHash + scope.convertDecimalToBase(randInt,scope.hashLength);
							location.hash  = genHash;
						} else {
							genHash = hash;
						}
						scope.currentHash = genHash;
						
					}
					
					
					document.getElementById("message").style.opacity = 1;
					document.getElementById("generatorButton").style.opacity = 1;
					document.getElementById("infolink").style.opacity = 1;
					
					document.getElementById("generatorButton").disabled = false;
					document.body.scrollTop = document.documentElement.scrollTop = 0;

					if (scope.debug) {
						console.log(scope.displayMessage);
						console.log(scope.curChars);
						console.log("Hash: " + scope.currentHash);
					}
				}, this.timeout, this);
			
			}
		},
	mounted: function() {
		axios.get(this.file)
		.then(response => {
			this.messages = response.data.split("\n");
			if (location.hash==undefined) this.newMessage();
			else this.newMessage(location.hash.substring(1));
			document.getElementById("deepGen").style.opacity = 1;
		})
	},
	created: function() {

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
